import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c849f158"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white rounded text-center pdf-loader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_pdf = _resolveComponent("pdf", true)!

  return (_openBlock(), _createBlock(_component_pdf, {
    class: "pdfvuer",
    resize: ""
  }, {
    loading: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Spinner)
      ])
    ]),
    _: 1
  }))
}